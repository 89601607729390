import React from 'react';
import styled, { css } from 'styled-components';
import isBrowser from '../utils/isBrowser';

export type ImageFetchPriority =
    React.ImgHTMLAttributes<HTMLImageElement>['fetchPriority'];

const Img = styled.img.withConfig({
    shouldForwardProp: (prop) => {
        // TODO: The `fetchpriority` attribute isn't recognised by Styled Components at the time of writing
        return ['fetchpriority', 'fetchPriority'].includes(prop);
    },
})<{
    $isImageLoaded: boolean;
}>`
    ${({ $isImageLoaded }): ReturnType<typeof css> | null => {
        return !$isImageLoaded
            ? css`
                  --imageOpacity: 0;
              `
            : null;
    }};

    /* Workaround for jumpy filter transition in Chrome... */
    will-change: opacity;

    /* These can be set by HOC */
    opacity: var(--imageOpacity);
    filter: var(--imageFilter);
`;

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
    onLoadCallback?: () => void;
}

export default function LazyImage({
    onLoadCallback,
    fetchPriority,
    ...rest
}: Props): React.ReactElement {
    const [isImageLoaded, setIsImageLoaded] = React.useState<boolean>(false);

    const onLoad = React.useCallback((): void => {
        if (!isImageLoaded) {
            setIsImageLoaded(true);
            if (onLoadCallback) {
                onLoadCallback();
            }
        }
    }, [onLoadCallback]);

    const imageRef = React.useRef<HTMLImageElement | null>(null);
    const pollForComplete = React.useCallback((): void => {
        if (isImageLoaded) return;
        const elm = imageRef.current;
        if (elm) {
            if (elm.complete) {
                onLoad();
            } else {
                requestAnimationFrame(pollForComplete);
            }
        }
    }, [isImageLoaded, onLoad]);

    const ref = React.useCallback(
        (elm: HTMLImageElement | null): void => {
            imageRef.current = elm;
            // Give react a chance to render before starting to poll
            // This gives us more chance of the opacity transition being visible
            // Should also fix rendering glitches in firefox where native image placeholder shows briefly
            setTimeout(pollForComplete, 300);
        },
        [imageRef, pollForComplete],
    );

    return (
        <Img
            {...rest}
            onLoad={isBrowser() ? onLoad : undefined}
            $isImageLoaded={isImageLoaded}
            ref={isBrowser() ? ref : undefined}
            /* @ts-ignore TS2769: No overload matches this call. React does not recognize the `fetchPriority` prop. */
            fetchpriority={fetchPriority}
        />
    );
}
